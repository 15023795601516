import React from 'react';
import "./home.css"
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import slideone from "./../assets/slide-one.jpg"
import slidetwo from "./../assets/slide-two.jpg"
import slidethree from "./../assets/slide-three.jpg"
import slidefour from "./../assets/slide-four.jpg"

export const Home = () => {
  return (
    <>
      <div className='home-container'>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          // scrollbar={{ draggable: false }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
        >
          <SwiperSlide><img src={slideone} alt="slideone" /></SwiperSlide>
          <SwiperSlide><img src={slidetwo} alt="slidetwo" /></SwiperSlide>
          <SwiperSlide><img src={slidethree} alt="slidethree" /></SwiperSlide>
          <SwiperSlide><img src={slidefour} alt="slidefour" /></SwiperSlide>
        </Swiper>

        <h2>Brainy Star Abacus conducts Abacus Classes for Kids. Classes are conducted twice a Week for 90 Minutes. Abacus Online Classes option is also available. There is flexibility in timings. Students can choose from wide/multiple variety of Courses.</h2>
      </div>
      <div className='home-para'>
        <h4>What is Abacus</h4>
        <p>The abacus (plural abaci or abacuses), also called a counting frame, is a calculating tool which has been used since ancient times. It was used in the ancient Near East, Europe, China, and Russia, millennia before the adoption of the Hindu-Arabic numeral system.[1] The exact origin of the abacus has not yet emerged. It consists of rows of movable beads, or similar objects, strung on a wire. They represent digits. One of the two numbers is set up, and the beads are manipulated to perform an operation such as addition, or even a square or cubic root.

          In their earliest designs, the rows of beads could be loose on a flat surface or sliding in grooves. Later the beads were made to slide on rods and built into a frame, allowing faster manipulation. Abacuses are still made, often as a bamboo frame with beads sliding on wires. In the ancient world, particularly before the introduction of positional notation, abacuses were a practical calculating tool. The abacus is still used to teach the fundamentals of mathematics to some children, for example, in Russia.

          Designs such as the Japanese soroban have been used for practical calculations of up to multi-digit numbers. Any particular abacus design supports multiple methods to perform calculations, including addition, subtraction, multiplication, division, and square and cube roots. Some of these methods work with non-natural numbers (numbers such as 1.5 and 3⁄4).

          Although calculators and computers are commonly used today instead of abacuses, abacuses remain in everyday use in some countries. Merchants, traders, and clerks in some parts of Eastern Europe, Russia, China, and Africa use abacuses. The abacus remains in common use as a scoring system in non-electronic table games. Others may use an abacus due to visual impairment that prevents the use of a calculator.[1]</p>
      </div>
    </>
  );
}   