import React from 'react';
import { Routes, Route, BrowserRouter, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Home } from './home';
import { About } from './about';
import { ContactUs } from './contact-us';
import { Footer } from './footer';
import { Summer } from './summer';
import logo from "./../assets/logo.jpg"
import "./navbar.css"



const NavBar = () => {
  return (
    <>
      <div>
        <Navbar expand="lg" className='navstyle'>
          <Navbar.Brand href="#"></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mr-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              <Nav.Link as={Link} to="/summer">Summer Camp</Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>


      <div>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="about" element={<About />} ></Route>
          <Route exact path="contact" element={<ContactUs />} ></Route>
          <Route exact path="summer" element={<Summer />} ></Route>
        </Routes>
      </div>

      <Footer />
    </>
  );
}
export default NavBar;
