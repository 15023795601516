import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import "./contact-us.css"
import emailjs from '@emailjs/browser';




export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("submit", form)
    if (form.current[0].value !== "" &&
      form.current[1].value !== "" &&
      form.current[2].value !== "") {
      emailjs.sendForm('service_97ly8fh', 'template_ul9u328', form.current, 'wn-pXx6h3-JUJrkN8')
        .then((result) => {
          alert("Your form submitted successfully")
          console.log(result.text);
          form.current[0].value = ""
          form.current[1].value = ""
          form.current[2].value = ""
        }, (error) => {
          alert("Error while submiting, Please retry later")
        });
    } else {
      alert("Please fill all the fields")
    }
  };


  return (
    <>
      <div className='contact'>
        <h1>Contact Us</h1>
        <hr />
        <p>Fill out the form below to get in touch with us:</p>
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input class="form-control" type="text" name="user_name" />
          <label>Email</label>
          <input class="form-control" type="email" name="user_email" />
          <label>Message</label>
          <textarea class="form-control" name="message" />
          <Button variant="primary" type="submit" value="send" className='button'>
            Submit
          </Button>
        </form>

      </div>

    </>
  );
}
