import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import NavBar from "./components/navbar";
import logo from "./assets/logo.jpg"
import summer from "./assets/summer.png"

function App() {
  const popup = useRef()
  const closeImage = () => {
    popup.current.style.display = "none"
  }

  const [isPopuptVisible, setIsPopupVisible] = useState(true);  
    // const popup = useRef(null);

    const handleClickOutside = (event) => {
        if (popup.current && !popup.current.contains(event.target)) {
          setIsPopupVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

  
  return (
    <>
      {isPopuptVisible && (<div className="popup" id="popuptest" onClick={closeImage} ref={popup}>
        <button id="close">&times;</button>
        <img src={summer} alt="img" />
        {/* <a href="#">Let's Go</a> */}
      </div>)}
      <div className='App-header'>
        <div className='banner'>
          <img src={logo} alt="logo" className='logo'></img>
          <span className="name">Brainy Star Abacus</span>
          <div className="phone"> Phone: 86106-73077</div>
        </div>
        <NavBar />
      </div>
    </>
  );
}

export default App;
